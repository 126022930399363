import { AlertService } from 'src/app/__core/services/alert.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Response as res, response } from 'src/app/__core/interfaces/response';  
import { Authenticate } from 'src/app/pages/home/interface/authenticate';
import { environment } from 'src/environments/environment'; 
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
 
  public isUserLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public User$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  constructor(
    private http: HttpClient,
    private lang: TranslationService,
    private alertService: AlertService,
    ) { } 

  setValue = (newValue): void => this.isUserLoggedIn$.next(newValue);

  getIsUserLoggedIn = (): Observable<boolean> => {
    if (localStorage.getItem(environment.appName)) {
      this.isUserLoggedIn$.next(true);
      let user: any | null = localStorage.getItem(environment.appName + "_UserData") as any | null ;
      this.User$.next(user);
      return this.isUserLoggedIn$.asObservable(); 
    }
    return this.isUserLoggedIn$.asObservable();
  }

  getUser = async (): Promise<boolean> => {
    if (localStorage.getItem(environment.appName)) {
      this.isUserLoggedIn$.next(true);
      let user: any | null = localStorage.getItem(environment.appName + "_UserData") as any | null ;
      this.User$.next(user);
      return user
    }
    return null
  }

  logout = () => { 
      this.isUserLoggedIn$.next(false); 
      this.alertService.show("success", this.lang.translate("logoutSuccessflly")); 
      localStorage.clear(); 
    }

  login = (req: FormData) => this.http.post<response<Authenticate>>(environment.baseUrl + 'user/authenticate', req);

}
