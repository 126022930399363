import { Injectable } from "@angular/core";
import { SnotifyPosition, SnotifyService } from "ng-snotify";

@Injectable({
  providedIn: 'root'
})

export class AlertService {

  constructor(private snotifyService: SnotifyService) { }

  show = (
    type: "success" | "error" | "info" | "warning",
    body: string,
    title: string = "",
    position: SnotifyPosition = SnotifyPosition.rightTop,
    duration: number = 4000
  ) => {
    switch (type) {
      case "success":
        this.snotifyService.success(body, title, {
          bodyMaxLength: 200,
          titleMaxLength: 35,
          backdrop: -1,
          timeout: duration,
          position: position,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true 
        });
        break;
      case "error":
        this.snotifyService.error(body, title, {
          bodyMaxLength: 200,
          titleMaxLength: 35,
          backdrop: -1,
          timeout: duration,
          position: position,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
        break;
      case "info":
        this.snotifyService.info(body, title, {
          bodyMaxLength: 200,
          titleMaxLength: 35,
          backdrop: -1,
          timeout: duration,
          position: position,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
        break;
      case "warning":
        this.snotifyService.warning(body, title, {
          bodyMaxLength: 200,
          titleMaxLength: 35,
          backdrop: -1,
          timeout: duration,
          position: position,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
        break;
    }
  }

}
