 
export const Arabic = {
    main: "الرئيسية",
    aboutUs: "عن مجلات",
    contactUs: "إتصل بنا",

    pleaseFillOutAllRequiredFields: "يرجى ملى الحقول المطلوبة",
    

    // Footer
    thakafatyPlatform: "منصة مجلات",
    privacy: "الخصـــوصــــية",
    terms: "الأحكــــــام",
    withTheEffortsOfTheMinistryOfCulture: "بجهود وزارة الثقافة",


    aboutAfkar: "عن افكار",
    aboutJeel: "عن جيل",
    aboutWesam: "عن وسام",
    aboutFunoun: "عن فنون",


    AfkarMagazine: "مجلة افكار",
    JeelMagazine: "مجلة جيل",
    WesamMagazine: "مجلة وسام",
    FunounMagazine: "مجلة فنون",

    aboutMajalat: "عن مجلات",  
    vision: "رؤيتنا",
    mission: "مهمتنا",
     
    contactUsDescription: "إذا كنت بحاجة إلى مساعدتنا، أو لديك أسئلة حول كيفية استخدام المنصة أو تواجه صعوبات فنية، فلا تتردد في الاتصال بنا.",

    FirstName: "الأسم الاول",
    LastName: "اسم العائلة",
    MobileNumber: "رقم الهاتف",
    Email: "البريد الإلكتروني",
    Message: "الرسالة",
    Subject: "الرسالة",
    contactUsProcessedSuccessfully: "سنتواصل معك في اقرب وقت, شكرا لتواصلك معنا",

    technicalSupport: "الدعم الفني",

    technicalSupportContactUsDescription: "تحقق من الموارد المفيدة والأسئلة الشائعة وأدوات المطورين.    ",
    MobileNumberContactUsDescription: "اتصل بنا للتحدث إلى أحد فريق الوزارة. نحن دائما سعداء للمساعدة.    ",
    emailContactUsDescription: "راسلنا عبر البريد الإلكتروني للاستفسارات العامة، بما في ذلك فرص المشاركة.    ",
 
    forHelp: "للمساعدة",
    search: "بحث",
    faq: "أسئلة مكررة",
    faqDescription: "كيفية الاستخدام لبدء إضافة المحتوى    ",
    
    faqPopup: "مركز المساعدة",
    login: "تسجيل الدخول",
    register: "تسجيل حساب عبر منصة ثقافة",
    saved: "المحفوظة",
    shared: "مشاركاتي",
    tabName: 'حسابي',
    magazineShare: "المشاركة بالمجلة",
    logout: "تسجيل الخروج",
    myProfile: "حسابي",
    blogTitle: "دراسات ومقالات",
    readMore: "اقرأ المزيد",
    more: "المزيد",
    relatedBlogs: "مقالات ذات صلة",
    blogs: "المقالات",
    browseNow: "تصفح الاّن",
    browes: "تصفح ",
    moreVersions: "المزيد من الاصدارات",
    afkarMagazineTitle: "مجلة افكار",
    afkarMagazineSubTitle: "الشركس في تاريخ و الأدب و الفن",
    magazinePagesNumber: "العدد 335",
    magazineDescription: "هي مجلة موجهة للأطفال تهدف إلى تعزيز قيم الاحترام والتفاهم بين الأطفال. تقدم المجلة محتوى ممتع وتثقيفي يشجع الأطفال على التعامل بلطف واحترام مع زملائهم في المدرسة والمجتمع.    " ,
    lastMagazines: "الأعداد السابقة",
    mostRead: "الاكثر قراءة",

    // Features
    blogsMagazines: "دراسات ومقالات",
    monthlyMagazine: "مجلة شهرية ثقافية",
    culturalWindowsMagazine: "نوافذ ثقافية",
    MagazineCreative: "إبداع", 
    otherMagazine: "مجلات اخرى",
    mostWritersSearched: "الكُتاب الأكثر قراءة",
    blogsAndArticales: "دراسات ومقالات",
    subscriptionText: "كن اول من يعرف عند اصدار العدد القادم من المجلة    ",
    registerNow: "سجل الاّن",
    email: "البريد الالكتروني", 


    funoneMagazineTitle: "مجلة فنون",
    funoneMagazineSubTitle: "الشركس في تاريخ و الأدب و الفن",
    jeelMagazineTitle: "مجلة جيل",
    wesamMagazineTitle: "مجلة وسام",
    theNumberIs: "العدد: ",
    magazines: "المجلات",
    theWriters: "الكُتاب",
    theDraw: "الرسام",
    interialContent: "المحتوى الداخلي",
    versionsNumber: "رقم الاصدارات",
    advanceSearch: "بحث متقدم", 
    searchInMajalat: "ابحث في مجلات",
    magazineAddress: "عنوان المجلة",
    versionNumber: "رقم الاصدار",
    writerName: "اسم الكاتب",
    keyWords: "كلمات مفتاحية",
    photographerName: "اسم المصور / اسم الرسام",
    drawerName: "اسم الرسام",
    date: "التاريخ",
    moreMagazines: "المزيد من الاعداد",
    reset: "إعادة التعين",
    showSearchResult: "عرض نتائج البحث",
    showSearchNumber: "نتائج البحث",
    sortAccording: "رتب حسب", 
    ReleaseNumberDesc: "من الاقدم الى الاحدث",
    ReleaseNumberAsc: "من الاحدث الى الاقدم",
    result: "نتيجة",
    for: "ل",
    filterOptions: "خيارات التصفية",
    loginHeader: "تسجيل دخول باستخدام حسابك في منصة ثقافة",
    password: "كلمة السر",
    phoneNumber: "رقم الهاتف",
    forgetPassword: "هل نسيت كلمة السر؟",
    dontHaveAnEmail: "لا يوجد لديك حساب؟",
    registerNowAnEmailIntoThaqafa: "انشئ الاّن حساب في منصة ثقافة",
    loginSuccessfully: "تم تسجيل الدخول بنجاح",
    logoutSuccessflly: "تم تسجيل الخروج بنجاح",
    magazineShareDescription: "يرجى إدخال المعلومات عن الشخص المراد تعبئة المحتوى عنه",
    SecondName: "اسم الاب",
    ThirdName: "اسم الجد", 
    PhoneNumber: "رقم الهاتف", 
    CategoryId: "المجلة",
    ArticleTitle: "عنوان الموضوع",
    ArticleDescription: "وصف الموضوع", 
    uploadManImage: "تحميل صورة رئيسية تعبيرية",
    uploadFiles: "تحميل المرفق",
    proofOfIdentity: "إثبات الشخصية",
    pleaseFillRightEmail: "يرجى ادخال بريد الكتروني صحيح",
    registerdSuccessfully: "تم الاشتراك في هذه المجلة",
    thisFieldIsRequired: "الحقل مطلوب",
    send: "ارسال",
    sendSuccessfully: "لقد تم ارسال طلبك بنجاح ",
    shareFilesHint1: "صيغة الصور المسموحة هي jpeg, jpg, png, حجم الصور المسموح هو 10.77 ميجابايت    ",
    shareFilesHint2: "صيغة الملفات المسموحة هي .doc,.docx,document,.pdf حجم الملفات المسموح هو 19.77 ميجابايت    ",
    shareFilesHint3: "صيغة الصور المسموحة هي jpeg, jpg, png, حجم الصور المسموح هو 10.77 ميجابايت",
    deleteImage: "حذف الصورة",
    file: "الملف",
    shareLink: "مشاركة الرابط",
    link: "الرابط",
    shareLinkHint: "يمكنك مشاركة الرابط من Google Drive او One Drive",
    SharedUrl: "تحميل المرفق او مشاركة الرابط",
    SharedUrlHint: "للمزبد من الملفات يرجى تحميلهم على Google Drive و مشاركة الرابط هنا",
    removeFile: "حذف الملف",
    share: "مشاركة",
    yearsFilter: "سنة الاصدار" ,
    showAll: "إظهار اقل",
    showLess: "إظهار الكل",
    section: "القسم",
    writers: "الكتاب",
    thePages: "الصفحات:",
    MagazineBookMarked: "تم حفظ المجلة",
    MagazineUnBookMarked: "تم الغاء حفظ المجلة",
    magazinePending: "مجلات بإنتظارك للقراءة...",
    profile: "حسابك الشخصي",
    yourProfile: "حسابك الشخصي",
    firstName: "الاسم الأول",
    familyName: "اسم العائلة",
    privcyEdit: "  لن يتم مشاركة معلوماتك الشخصية مع أحد سوى وزارة الثقافة",
    editInfo: "تعديل المعلومات",
    ReleaseTitle: "عنوان المجلة",
    ReleaseYear: "رقم الاصدار",
    AuthorName: "اسم الكاتب",
    Keyword: "ابحث في مجلات", 
    PainterName: "اسم الرسام",
    Tags: "كلمات مفتاحية",
    Date: "التاريخ",
    enterRightNumber: "يرجى ادخال رقم",
    emptyTitle: "لا توجد مجلات للعرض",
    clickHereToGoToHomePage: "اضغط على مجلات للعودة على صفحة الرئيسية و التعرف على المزيد منهم",
    homePage: "الصفحة الرئيسية",
    doYouWantToSubscripeAllOtherMagazines: "هل تريد الاشتراك في المجلات الاخرى؟", 
    subscripeToAllMagazines: "الاشتراك في جميع المجلات ",
    dontSubscripeToAllMagazines: "عدم الاشتراك",
    subscripedToAllMagazines: "تم الاشتراك في المجلات",
    lastRead: "صفحات في انتظار القراءة من حيث تركت",
    ReleaseNumber: "رقم الاصدار",
    subscripedToِAfkar: "تم الاشتراك في مجلة افكار",
    subscripedToFunone: "تم الاشتراك في مجلة فنون",
    subscripedToJeel: "تم الاشتراك في مجلة صوت الجيل",
    subscripedToWesam: "تم الاشتراك في مجلة وسام",
    save: "حفظ",
    close: "اغلاق"

    

}