import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/__core/services/alert.service';
import { TranslationService } from 'src/app/__core/services/translation.service';
import { HomeService } from '../../home.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/__core/services/auth.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ess-login-popup-shared',
  templateUrl: './login-popup-shared.component.html',
  styleUrls: ['./login-popup-shared.component.scss']
})
export class LoginPopupSharedComponent implements OnInit {

  form: FormGroup;
  
  constructor(
    private alertService: AlertService,
    private lang: TranslationService,
    private service: HomeService,
    private authService: AuthService,
    private dialog: MatDialogRef<LoginPopupSharedComponent>,
  ) { }

  ngOnInit(): void { 
    this.form = new FormGroup({
      phoneNumber: new FormControl("", [ Validators.maxLength(11), Validators.minLength(5), Validators.required]),
      password: new FormControl("",Validators.required),
    }) 
  }

  login = () => { 
    if (this.form.invalid) { 
      this.form.markAllAsTouched();
      this.alertService.show("error", this.lang.translate("pleaseFillOutAllRequiredFields"));
      return
    } 

    let req = new FormData(); 
    let phoneNumber: string = this.form.get("phoneNumber")?.value;
    if (phoneNumber[0] == '0') { 
      phoneNumber = phoneNumber.slice(1, phoneNumber.length);
    }

    if (!phoneNumber) return;
    
    
    req.append("Username", "+962" + phoneNumber);
    req.append("Password", this.form.get("password")?.value);
    
    this.service.login(req).subscribe(res => { 
      if (res.errorCode == 0) { 
        this.authService.setValue(true);
        localStorage.setItem(environment.appName + "_UserData", JSON.stringify(res.data));
        localStorage.setItem(environment.appName, res.data.token); 
        this.alertService.show("success", this.lang.translate("loginSuccessfully"))
        this.dialog.close(true);
      } else { 
         this.alertService.show("error", res.errorMessage);
      }
    }) 
  }

  goThaqafaForgetPassword = () => { 
    window.open("https://www.thakafah.jo/auth/forget-password", "_blank");
  }
  
  geThaqafaRegister = () => { 
    window.open("https://www.thakafah.jo/auth/siginup", "_blank"); 
  }

}
