import { Directive } from '@angular/core';

@Directive({
  selector: '[appRole]'
})
export class RoleDirective {

  constructor() { }
  
}
