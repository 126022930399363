import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";


@Injectable({
  providedIn: 'root'
})
export class MatSnackBarService {

  defaultErrorMsg = "Something has went wrong";
  defaultSuccessMsg = "Done Successfully"; 
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  

  constructor(private matSnackBar: MatSnackBar) { }

  error = (msg = this.defaultErrorMsg) => this.matSnackBar.open(msg, 'close',  {
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
    duration: 1800, panelClass: "bg-danger"
  });
    
  success = (msg = this.defaultSuccessMsg) => this.matSnackBar.open(msg, '', { 
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
    duration: 1800, panelClass: "bg-success" 
  });

  info = (msg: string) => this.matSnackBar.open(msg, '', { 
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
    duration: 1800, panelClass: '' 
  });

  warning = (msg: string) => this.matSnackBar.open(msg, '', { 
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
    duration: 1800, panelClass: ["bg-warning" , "text-white"] 
  });
}
