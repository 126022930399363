import { Inject, Injectable } from '@angular/core'; 
import { DOCUMENT } from '@angular/common'; 
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {

  splashScreenElem: any;

  constructor(@Inject(DOCUMENT) document: Document) {
    window.addEventListener('load', () => { 
      this.splashScreenElem = document.getElementById('ess-splash-screen');
      setTimeout(() => { 
        this.splashScreenElem.style.display = "none"; 
      }, environment.production ? 2500 : 1000) 
    });  
  }  
} 
