import { MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'ess-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteDialogComponent{

  constructor(private dialog: MatDialogRef<DeleteDialogComponent>) { }  
  delete = () => this.dialog.close(true); 
}
