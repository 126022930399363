
import { Injectable } from "@angular/core"; 
import { HomeService } from "src/app/pages/home/home.service";
import { environment } from "src/environments/environment";  

@Injectable({providedIn: "root"})
export class AppService { 

  constructor(private homeService: HomeService) {

  }
  
  imageUrl: string = "";
  isLoading: boolean = false;
  baseImage: string = environment.baseUrl + "File/";
  defaultImage: string = "assets/img/demo/default.jpg"; 

  showLoading = () => true;

  dismissLoading = () => false; 

  getImage = (name: string) => name ? environment.baseUrl + "File/" + name : "assets/imgs/auth/upload.png"

  openBook = (pdf, releaseId, pageNumber = 0, searchKeyword = "", openBookOnPageNumber = 0) => {   
    this.homeService.VisitHistoryAddReleaseVisit({releaseId, pageNumber, searchKeyword}).subscribe();
    if (!pageNumber) { 
      window.open(environment.pdfViewerUrl + pdf + "&pdfId=" + releaseId, "_blank");   
    } else { 
      window.open(environment.pdfViewerUrl + pdf + "&pdfId=" + releaseId + "#pdfflip-PDFF/" + pageNumber + "/", "_blank");   
    }
  }
  
} 