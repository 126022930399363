import { NavItem } from "../../../__core/interfaces/navItem";

export const NavItemsLoggedIn: NavItem[] = [
    { isIcon: false, name: "main", router: "", id: 1 },
    { isIcon: false, name: "aboutUs", router: "/about-us", id: 2 },
    { isIcon: false, name: "magazineShare", router: "/magazine-share", id: 3 },  
    { isIcon: false, name: "contactUs", router: "/contact-us", id: 4 },   
    { isIcon: true, name: "bi bi-search", router: "/search", id: 5 },   
]; 

export const NavItemsLoggedOut: NavItem[] = [
    { isIcon: false, name: "main", router: "", id: 1 },
    { isIcon: false, name: "aboutUs", router: "/about-us", id: 2 },
    { isIcon: false, name: "magazineShare", router: "/magazine-share", id: 3 },  
    { isIcon: false, name: "contactUs", router: "/contact-us", id: 4 },   
    { isIcon: true, name: "bi bi-search", router: "/search", id: 5 },   
]; 