import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { interseptors } from './utils/interceptors'; 

@NgModule({
  declarations: [ 
  ],
  imports: [
    CommonModule,
    MatSnackBarModule, 
  ],
  providers: [...interseptors]
})
export class CoreModule { } 