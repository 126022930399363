import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core"; 
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class AuthInterceptor implements HttpInterceptor { 

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): any  { 
        const clonedRequest = request.clone({
            headers: new HttpHeaders({
                "Authorization": `Bearer ${localStorage.getItem(environment.appName)}`,
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': `ar`, 
                'No-Auth': "True"
            }) 
        });  
        return next.handle(clonedRequest); 
    } 
}