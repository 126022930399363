let live = "https://www.mjallat.jo/api/api/";
let test = "https://www.mjallat.jo/api/api/";   

const baseThaqafaUrl = "https://www.thakafah.jo/api/api/"

const baseUrl = test;

export const environment = {
  production: true,  
  baseUrl: baseUrl, 
  appName: 'Mjallat',
  baseFileUrl: baseUrl + "File/get2/",
  pdfViewerUrl: "https://www.mjallat.jo/pdf-viewer/?pdf=",
  baseFileThaqafaUrl: baseThaqafaUrl + "File/", 
  credantialsTesting: {
    userName: "+962111111111",
    password: "P@ssw0rd"
  },
}; 