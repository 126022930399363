import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Configuration } from '../../home/interface/configuration';
import { HomeService } from '../../home/home.service';
import { AboutMagazine } from '../../home/interface/about';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ess-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class FooterComponent {
  appName: string = environment.appName;
  currentYear: number;
  Configuration: Configuration;

  
  label$: BehaviorSubject<string> = new BehaviorSubject("");
  facebookPage$: BehaviorSubject<string> = new BehaviorSubject("");
  youtubePage$: BehaviorSubject<string> = new BehaviorSubject("");
  twitterPage$: BehaviorSubject<string> = new BehaviorSubject("");
  instagramPage$: BehaviorSubject<string> = new BehaviorSubject("");
  whatsAppNumber$: BehaviorSubject<string> = new BehaviorSubject(""); 

  constructor(
    private router: Router,
    private service: HomeService,
  ) { }

  ngOnInit(): void {
    this.getCurrentYear();
    this.getConfiguration();



    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) { 
        if (event.url.startsWith("/afkar/home-page") || event.url.startsWith("/afkar/about")) { 
          this.label$.next("AfkarMagazine");
          this.getAfkarAbout()
        }
        else if (event.url.startsWith("/alJeel/home-page") || event.url.startsWith("/alJeel/about")) {
          this.label$.next("JeelMagazine"); 
          this.getJeelAbout(); 
        }
        else if (event.url.startsWith("/funone/home-page") || event.url.startsWith("/funone/about")) {
          this.label$.next("FunounMagazine");
          this.getFunoneAbout(); 
        }
        else if (event.url.startsWith("/wesam/home-page") || event.url.startsWith("/wesam/about")) {
          this.label$.next("WesamMagazine");
          this.getWesamAbout();
        } else {
          this.label$.next("withTheEffortsOfTheMinistryOfCulture");
          this.getConfiguration();
        }
      }
    }); 
  }

 

  getCurrentYear = () => {
    this.currentYear = new Date().getFullYear()
  };


  getConfiguration = () => {
    this.service.getConfiguration().subscribe(res => { 
      this.Configuration = res.data;  
      this.Configuration.company.facebook && this.facebookPage$.next(this.Configuration.company.facebook);
      this.Configuration.company.youtubePage && this.youtubePage$.next(this.Configuration.company.youtubePage);
      this.Configuration.company.twitter && this.twitterPage$.next(this.Configuration.company.twitter);
      this.Configuration.company.instagram && this.instagramPage$.next(this.Configuration.company.instagram);
      this.Configuration.company.whatsApp && this.whatsAppNumber$.next("https://api.whatsapp.com/send?&text=" + this.Configuration.company.whatsApp ); 
    })
  }


  getAfkarAbout = () => {
    this.service.getAboutAfkar().subscribe(res => {  
      res.data.facebookPage && this.facebookPage$.next(res.data.facebookPage);
      res.data.youtubePage && this.youtubePage$.next(res.data.youtubePage);
      res.data.twitterPage && this.twitterPage$.next(res.data.twitterPage);
      res.data.instagramPage && this.instagramPage$.next(res.data.instagramPage);
      res.data.whatsAppNumber && this.whatsAppNumber$.next("https://api.whatsapp.com/send?&text=" + res.data.whatsAppNumber); 
    });
  }


  getWesamAbout = () => {
    this.service.getAboutWesam().subscribe(res => {  
      res.data.facebookPage && this.facebookPage$.next(res.data.facebookPage);
      res.data.youtubePage && this.youtubePage$.next(res.data.youtubePage);
      res.data.twitterPage && this.twitterPage$.next(res.data.twitterPage);
      res.data.instagramPage && this.instagramPage$.next(res.data.instagramPage);
      res.data.whatsAppNumber && this.whatsAppNumber$.next("https://api.whatsapp.com/send?&text=" + res.data.whatsAppNumber); 
    });
  }


  getJeelAbout = () => {
    this.service.getAboutJeel().subscribe(res => { 
      res.data.facebookPage && this.facebookPage$.next(res.data.facebookPage);
      res.data.youtubePage && this.youtubePage$.next(res.data.youtubePage);
      res.data.twitterPage && this.twitterPage$.next(res.data.twitterPage);
      res.data.instagramPage && this.instagramPage$.next(res.data.instagramPage);
      res.data.whatsAppNumber && this.whatsAppNumber$.next("https://api.whatsapp.com/send?&text=" + res.data.whatsAppNumber); 
    });
  }


  getFunoneAbout = () => {
    this.service.getAboutFunon().subscribe(res => { 
      res.data.facebookPage && this.facebookPage$.next(res.data.facebookPage);
      res.data.youtubePage && this.youtubePage$.next(res.data.youtubePage);
      res.data.twitterPage && this.twitterPage$.next(res.data.twitterPage);
      res.data.instagramPage && this.instagramPage$.next(res.data.instagramPage);
      res.data.whatsAppNumber && this.whatsAppNumber$.next("https://api.whatsapp.com/send?&text=" + res.data.whatsAppNumber); 
    });
  }



  openLink = (link: any) => window.open(link.value, "_blank");
  
  
} 



