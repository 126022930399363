import { Injectable } from '@angular/core';
import { English } from 'src/assets/i18n/en';
import { Arabic } from 'src/assets/i18n/ar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  currentLanguage: string | null = "en";
  labelsApp: any;
  direction: any = 'rtl'
  defaultLanguage = "ar"

  constructor() { }

  handleLanguage() {
    this.currentLanguage = localStorage.getItem(environment.appName + '_language');

    if (this.currentLanguage) {
      this.currentLanguage == 'en' ? this.initiateLanguage('en') : this.initiateLanguage('ar')
    } else {
      localStorage.setItem(environment.appName + '_language', this.defaultLanguage);
      this.initiateLanguage(this.defaultLanguage);
    }
  }

  initiateLanguage(language: string) {

    if (language === 'en') {
      this.currentLanguage = 'en';
      document.dir = 'ltr'
    } else {
      this.currentLanguage = 'ar'
      document.dir = 'rtl'
    }
    this.labelsApp = this.getLabelsApp(this.currentLanguage);
  }

  getLabelsApp(language: string) {
    return this.labelsApp = (language == 'en' ? English : Arabic);
  }

  translate = (label: string) => this.labelsApp[label] ? this.labelsApp[label] : label;

}



