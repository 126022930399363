import { Component } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Title } from '@angular/platform-browser';
import { environment as env } from 'src/environments/environment';
import { TranslationService } from './__core/services/translation.service';
import { AppService } from './__core/services/app.service'; 
import { SplashScreenService } from './__core/services/splash-screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ],
})
export class AppComponent {

  splashScreenElem: any;
  isScrolled = false; 

  constructor(
    private title: Title,
    private translate: TranslationService, 
    private SplashScreenService: SplashScreenService, 
    public appService: AppService
  ) { } 
   
  ngOnInit(): void {  
    // this.title.setTitle(env.appName)
    this.translate.handleLanguage();  
  }





  
}
 