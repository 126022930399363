import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [  
  {
    path: '',
    redirectTo: '/', 
    pathMatch: 'full',
  },
  { 
    path: "", 
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: "**",
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  }  
]   
@NgModule({
  // imports: [RouterModule.forRoot(routes, {
  //   preloadingStrategy: QuicklinkStrategy,
  //   scrollPositionRestoration: 'enabled', 
  //   relativeLinkResolution: 'corrected',
  //   anchorScrolling: 'enabled', 
  // }), QuicklinkModule],
  imports: [RouterModule.forRoot(routes, { 
    scrollPositionRestoration: 'enabled', 
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled', 
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { } 
