import { ProfileMenu } from "src/app/__core/interfaces/profileMenu";

 

export const ProfileMenuDataLoggedOut: ProfileMenu[] = [
  {
    label: "login",
    router: "",
    tabName: 'login',
    isHaveAction: true,
    isHidden: false
  },
  {
    label: "register",
    router: "",
    tabName: 'register',
    isHaveAction: true,
    isHidden: false
  },
  { 
    label: "faqPopup",
    router: "/faq",
    tabName: 'changePassword',
    isHaveAction: false,
    isHidden: false
  }, 
]
 

export const ProfileMenuDataLoggedIn: ProfileMenu[] = [
  {
    label: "saved",
    router: "/saved",
    tabName: 'saved',
    isHaveAction: false,
    isHidden: false
  },
  // {
  //   label: "shared",
  //   router: "/shared",
  //   tabName: 'myFavorites',
  //   isHaveAction: false,
  //   isHidden: false
  // },
  {
    label: "myProfile", 
    router: "/profile/",
    tabName: 'myProfile',
    isHaveAction: false,
    isHidden: false
  },
  { 
    label: "faqPopup",
    router: "/faq",
    tabName: 'changePassword',
    isHaveAction: false,
    isHidden: false
  }, 
  // {
  //   label: "magazineShare",
  //   router: "/magazine-share",
  //   tabName: 'magazineShare',
  //   isHaveAction: false,
  //   isHidden: false
  // },
  {
    label: "logout",
    router: "",
    tabName: 'settings',
    isHaveAction: true,
    isHidden: false
  },
]