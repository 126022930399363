import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RoleDirective } from './__core/directive/role.directive';
import { CoreModule } from 'src/app/__core/core.module'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';   
import { SidenavItemsComponent } from './shared/components/sidenav-items/sidenav-items.component'; 
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';  
import { HttpClientModule } from '@angular/common/http'; 
import { SharedModule } from './shared/shared.module';
import { HeaderComponent } from './pages/layout/__header/header.component';
import { FooterComponent } from './pages/layout/__footer/footer.component';
 
@NgModule({
  declarations: [  
    AppComponent, 
    RoleDirective,     
    SidenavItemsComponent,  
    HeaderComponent,
    FooterComponent,
  ],   
  imports: [ 
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule, 
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    BrowserTransferStateModule, 
    HttpClientModule,
    SnotifyModule
  ],    
  exports: [ 
    HeaderComponent,
    FooterComponent,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: "appearance"},
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults}, 
    SnotifyService,  
    DatePipe
  ], 
  bootstrap: [AppComponent], 
})
export class AppModule { }

