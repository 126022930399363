import { Component, OnInit, Inject, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,  } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'ess-go-register-or-login',
  templateUrl: './go-register-or-login.component.html',
  styleUrls: ['./go-register-or-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoRegisterOrLoginComponent implements AfterViewInit {

  message: string;
  header: string;
  currentLanguage = this.lang.currentLanguage;

  constructor(
    private dialog: MatDialogRef<GoRegisterOrLoginComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cd: ChangeDetectorRef,
    private lang: TranslationService
  ) { }


  ngAfterViewInit(): void { 
    this.message = this.data.message;
    this.header = this.data.header;
    this.cd.detectChanges();
  }

  goLogin = () => {
    this.router.navigate(['/auth/login-social-media'])
    window.scroll(0, 0);
    this.dialog.close()
  }
  
  close = () => this.dialog.close();
  
  goRegister = () => {
    this.router.navigate(['/auth/siginup-social-media']);
    this.dialog.close()
  }
  
}
