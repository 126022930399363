import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ess-confirm-message',
  templateUrl: './confirm-message.component.html',
  styleUrls: ['./confirm-message.component.scss']
})
export class ConfirmMessageComponent implements OnInit {

  title: string = "";
  body: string = "";
  buttonColorClass: "btn-danger" | "btn-primary";
  buttonText: string = "";

  constructor(
    public dialog: MatDialogRef<ConfirmMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void { 
    this.title = this.data.title;
    this.body = this.data.body;
    this.buttonColorClass = this.data.buttonColorClass;
    this.buttonText = this.data.buttonText;
  }

  save = () => { 
    this.dialog.close(true);
  }
  
}
