import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core'; 
import { fadeInDown800ms } from 'src/app/__core/animations/fadeInDown';
import { ProfileMenuDataLoggedOut, ProfileMenuDataLoggedIn } from './ProfileMenuData';
import { ProfileMenu } from 'src/app/__core/interfaces/profileMenu';
import { NavItemsLoggedIn, NavItemsLoggedOut } from './NavItemsLoggedIn';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog'; 
import { LoginPopupSharedComponent } from '../../home/components/login-popup-shared/login-popup-shared.component';
import { AuthService } from 'src/app/__core/services/auth.service';


@Component({
  selector: 'ess-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeInDown800ms],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HeaderComponent {

  NavItems = NavItemsLoggedOut;
  showPopup: boolean = false;
  isUserLoggedIn
  ProfileMenu: ProfileMenu[] = ProfileMenuDataLoggedOut;
  navbarType: "navbarWithScroll" | "navbarWithoutScroll" = "navbarWithoutScroll"
  username: string;
  isSearchBoxOpen: boolean = false;
  toggleTab: boolean = false;


  constructor( 
    private cd: ChangeDetectorRef, 
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService
    ) { }

  ngOnInit(): void {


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        let aboutIndex = this.NavItems.findIndex(url => url.id == 2);

        if (event.url.startsWith("/afkar/home-page") || event.url.startsWith("/afkar/about")) { 
          this.NavItems[aboutIndex].name = "aboutAfkar"
          this.NavItems[aboutIndex].router = "afkar/about"; 
        } 
        else if (event.url.startsWith("/alJeel/home-page") || event.url.startsWith("/alJeel/about") ) { 
          this.NavItems[aboutIndex].name = "aboutJeel"
          this.NavItems[aboutIndex].router = "alJeel/about"
        } 
        else if (event.url.startsWith("/funone/home-page") || event.url.startsWith("/funone/about") ) { 
          this.NavItems[aboutIndex].name = "aboutFunoun"
          this.NavItems[aboutIndex].router = "funone/about"
        } 
        else if (event.url.startsWith("/wesam/home-page") || event.url.startsWith("/wesam/about") ) { 
          this.NavItems[aboutIndex].name = "aboutWesam"
          this.NavItems[aboutIndex].router = "wesam/about"
        } else {
          this.NavItems[aboutIndex].name = "aboutUs"
          this.NavItems[aboutIndex].router = "about-us" 
        }
        
  
      }
    }); 

    this.authService.getIsUserLoggedIn().subscribe((value) => {
      this.isUserLoggedIn = value; 
      setTimeout(() => { 
        this.ProfileMenu = this.isUserLoggedIn ? ProfileMenuDataLoggedIn : ProfileMenuDataLoggedOut
        this.NavItems = this.isUserLoggedIn ? NavItemsLoggedIn : NavItemsLoggedOut
        // this.username = this.getUserName();
        this.cd.detectChanges()
        this.cd.detectChanges()
      }, 500) 
      this.cd.detectChanges()
    });
  }

  ngOnDestroy(): void {
  } 

  doAction = (menu: ProfileMenu) => { 
    if (!menu.isHaveAction) {  
      this.router.navigate([menu.router]);
    } else { 
      switch (menu.label) { 
        case "login": this.login(); break;
        case "register": this.signUp(); break;
        case "logout": this.logout(); break;
      }
    }
  }


  login = () => {
    this.dialog.open(LoginPopupSharedComponent, { width: '440px' } )
  }

  signUp = () => { 
    window.open("https://www.thakafah.jo/auth/siginup", "_blank");
  }

  logout = () => { 
    this.authService.logout();
  }

}
