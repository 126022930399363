
import { animate, style, transition, trigger, query, stagger, animateChild } from '@angular/animations';

export function fadeInDownAnimation(duration: number) {
    return (trigger('stagger', [
        transition('* => *', [
            query(':enter', stagger(40, animateChild()), { optional: true }),
        ])
    ]),
        trigger('fadeInDown', [
            transition(':enter', [
                style({
                    transform: 'translateY(-60px)',
                    opacity: 0
                }),
                animate(`${duration}ms cubic-bezier(0.35, 0, 0.25, 1)`, style({
                    transform: 'translateY(0)',
                    opacity: 1
                }))
            ])
        ]))
}

export const fadeInDown800ms = fadeInDownAnimation(800); 
export const fadeInDown3000ms = fadeInDownAnimation(3000); 