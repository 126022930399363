
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmptyResponse, response, responsePaging } from 'src/app/__core/interfaces/response';
import { environment as e, environment } from 'src/environments/environment';
import { AboutMagazine, AboutUs } from './interface/about';
import { Configuration } from './interface/configuration';
import { FAQ } from './interface/faq';
import { Blog } from './interface/blog';
import { Authenticate } from './interface/authenticate';
import { Shelf } from './interface/shelf';
import { DefaultArtical } from './interface/defaultArtical';
import { MostSearchedAuthors } from './interface/mostSearchedAuthors';
import { Magazine } from './interface/magazine';
import { Search } from './interface/searchOutput';
import { OtherMagazines } from './interface/otherMagazine';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }



  // About
  getAboutUs = () => this.http.get<response<AboutUs>>(e.baseUrl + "About/Majalat");
  getAboutFunon = () => this.http.get<response<AboutMagazine>>(e.baseUrl + "About/Funon");
  getAboutJeel = () => this.http.get<response<AboutMagazine>>(e.baseUrl + "About/Jeel");
  getAboutWesam = () => this.http.get<response<AboutMagazine>>(e.baseUrl + "About/Wesam");
  getAboutAfkar = () => this.http.get<response<AboutMagazine>>(e.baseUrl + "About/Afkar");


  // Contact 
  contactUs = (req: FormData) => this.http.post<EmptyResponse>(e.baseUrl + "contact-us", req);

  // FAQs 
  getFAQs = () => this.http.get<responsePaging<FAQ[]>>(e.baseUrl + "faq?PageSize=99&PageNumber=1");

  // Configuration 
  getConfiguration = () => this.http.get<response<Configuration>>(e.baseUrl + "configuration/Get");

  // Blogs 
  getBlogs = (req: FormData) => this.http.post<responsePaging<Blog[]>>(e.baseUrl + "Topics/Published", req);
  getBlogById = (id: number) => this.http.get<response<Blog>>(e.baseUrl + "Topics/Get/" + id);


  // LOGIN
  login = (req: FormData) => this.http.post<response<Authenticate>>(e.baseUrl + 'user/authenticate', req);


  // Shelf
  getMagazines = () => this.http.get<any>(environment.baseUrl + "Shelf/ShelfInfo");


  // DefaultArtical 
  getDefaultArticle = (magazineId: number) => this.http.get<response<DefaultArtical>>(environment.baseUrl + "Home/DefaultArticle/" + magazineId);


  // MostSearchedAuthors

  getMostSearchedAuthors = (BaseMagazineId: number = 0, PageSize: number = 4, PageNumber: number = 1) => this.http.get<responsePaging<MostSearchedAuthors[]>>(e.baseUrl + `Home/MostSearchedAuthors/${BaseMagazineId}/${PageSize}/${PageNumber}`)
  MostReadArticles = (BaseMagazineId: number = 0, PageSize: number = 4, PageNumber: number = 1) => this.http.get<responsePaging<Magazine[]>>(e.baseUrl + `Home/MostReadArticles/${BaseMagazineId}/${PageSize}/${PageNumber}`)

  // SubscribeToNewsletter 
  SubscribeToNewsletter = (req: FormData) => this.http.post<any>(e.baseUrl + "Home/SubscribeToNewsletter", req)
  SubscribeToNewsletters = (req: FormData) => this.http.post<any>(e.baseUrl + "Home/SubscribeToNewsletters", req)

  // getPreviousArticles
  getPreviousArticles = (BaseMagazineId: number = 0, PageSize: number = 4, PageNumber: number = 1) => this.http.get<responsePaging<Magazine[]>>(e.baseUrl + `Home/PreviousArticles/${BaseMagazineId}/${PageSize}/${PageNumber}`)

  // MagazineShare  
  ParticipationsSendRequest = (req: FormData) => this.http.post<EmptyResponse>(e.baseUrl + "Participations/SendRequest", req)


  // SearchResult
  Search = (req: FormData) => this.http.post<responsePaging<Search[]>>(e.baseUrl + "Search/Search", req);


  // BookMark
  bookmark = (MagazineId: number) => this.http.post<EmptyResponse>(e.baseUrl + "Favourites/Create?ReleaseId=" + MagazineId, null);
  removeBookmark = (MagazineId: number) => this.http.delete<EmptyResponse>(e.baseUrl + "Favourites/Delete?ReleaseId=" + MagazineId);
  GetBookmarks = (pageSize: number, pageNumber: number) => this.http.get<responsePaging<Magazine[]>>(e.baseUrl + `Favourites/Get/${pageSize}/${pageNumber}`); 



  // getProfileInfo
  getProfileInfo = () => this.http.get<response<any>>(e.baseUrl + "user");


  ArticleTypes = (BaseMagazineId: number) => this.http.get<response<{id,name}[]>>(e.baseUrl + "Search/ArticleTypes/" + BaseMagazineId);
  

  // /VisitHistory/AddMagazineVisit
  VisitHistoryAddMagazineVisit = (id) => this.http.get<any>(e.baseUrl + "VisitHistory/AddMagazineVisit/" + id);


  // VisitHistory/AddReleaseVisit/1
  VisitHistoryAddReleaseVisit = (req) => this.http.post<any>(e.baseUrl + "VisitHistory/AddReleaseVisit", req); 

  // getOtherMagazines
  getOtherMagazines = (req: FormData) => this.http.post<responsePaging<OtherMagazines[]>>(e.baseUrl + "OtherMagazines/Search", req); 

  // GetLastReadMagazines
  GetLastReadMagazines = (pageSize: number, pageNumber: number) => this.http.get<responsePaging<any>>(e.baseUrl + `VisitHistory/ReadingPane/${pageSize}/${pageNumber}`); 
  
}