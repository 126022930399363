export const English = {


    // HEADER
    home : "Home",
    store : "Store",
    offers : "Offers",
    myOrders : "My Orders",
    cart : "Cart",
    restaurants : "Restaurants", 
    register : "Register", 
    login : "Login", 


    // FOOTER
    footerDescription: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
    complain: "Complain",
    privacyPolicy: "Privacy Policy",
    userPolicy: "User Policy",
    contactUs: "Contact Us",
    aboutUs: "About Us", 
    needHelp : "Need Help?",
    coveredArea: "Covered Area",


    // FormValidation 
    thisFieldIsRequired: "This Field is required",
    moreThanRequired: "More than required",
    lessThanRequired: "Less than required",
    passwordDoesnotMatched: "Password Does not Matched",



    // AUTH
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email", 
    invalidEmailFormat: "Invalid Email Format",
    lettersAndSymbolsNotAllowed: "Letters And Symbols Not Allowed",
    itShouldStartWithACapitalLetterAndContainSymbolsLettersAndNumbers: "Itt Should start with a capital letter and contain symbols, letters and numbers",
    phoneNumber: "Phone Number",
    password: "Password",
    confirmPassword: "ConfirmPassword",
    emailOrMobileNumber: "Email or Mobile Number",
    forgetPassword: "Forget Password?",
    keepMeLoggedIn: "Remember Me",
    pleaseFillOutAllRequiredFields: "Please Fill Out All Required Fields",




    // HOME 

    the: "The ", 
    best: "Best", 
    fromTheBestPlaces: "From The Best Places",
    orderNow: "Order Now", 
    storiesNearYou: "Stories Near You",
    searchForStore: "Search For Store",
    bestOffers: "Best Offers",
    freeShippingOnFirstOrder: "Free Shipping on first order",    
    optimalDeliveryTime: "Optimal delivery time",    
    bestQualityGuarantee: "Best quality guarantee",    
    varietyOfDishes: "Variety of dishes",    
    signUpForUpdatesAndGetFreeShipping: "Sign up for updates and get free shipping",
    everythingYouOrderWillBeQuicklyDeliveredToYourDoor: "Everything you order will be quickly delivered to your door.",
    weGetOnlyTheBestRestaurantsToMakeTheTastyFreshFoodForYou: "We get only the best Restaurants to make the tasty fresh food for you.",
    inOurListYouWillFindAWideVarietyOfDishesDessertsAndDrinks: "In our list  you’ll find a wide variety of dishes, desserts, and drinks.",
    mobileApp: "mobile App",
    mobileAppTextSection: "Our app is now available to download on the Google store and App store",

    
    // Restaurant
    reviews: "Reviews",
    storesNearYou: "Stores Near You",
    rating: "Rating"
    
    
}; 