import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'ess-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageLoaderComponent implements OnInit {

  @Input() loader: string = 'assets/imgs/spinners/giphy.gif';
  @Input() isFit: boolean = false;
  @Input() height: number = 100;
  @Input() width: number = 100;
  @Input() src: string;
  @Input() alt: string;
  @Input() sizeType: string = "%";

  appName: string = env.appName;
  isLoading: boolean;

  constructor(public app: AppService) {
    this.isLoading = true;  
  }

  ngOnInit(): void {
    // this.image = this.image ? this.app.baseImage + this.image : this.app.defaultImage;
  }

  hideLoader() {
    this.isLoading = false;
  }
  onError = () => {
    this.src = "assets/imgs/auth/upload.png";
    this.isLoading = false;
  }

}
