 
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common'; 
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input'; 
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip'; 
import { IconComponent } from './components/icon/icon.component';  
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 


// Components 
import { ImageLoaderComponent } from '../__core/components/image-loader/image-loader.component';  
import { DeleteDialogComponent } from '../__core/components/delete-dialog/delete-dialog.component';
import { GoRegisterOrLoginComponent } from '../__core/dialogs/go-register-or-login/go-register-or-login.component';  
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'; 
import { ConfirmMessageComponent } from './components/confirm-message/confirm-message.component';  
import { TranslatePipe } from '../__core/pipes/translate.pipe';
import { LoaderComponent } from '../loader/loader.component';  
 import { MatDialogModule } from '@angular/material/dialog';


const shared = [ 
  MatToolbarModule, 
  MatInputModule,    
  MatRippleModule,
  MatTooltipModule, 
  MatSelectModule,
  ReactiveFormsModule,
  FormsModule, 
  MatDatepickerModule,
  MatNativeDateModule,  
  NgxSpinnerModule, 
  MatDialogModule
];

@NgModule({
  declarations: [
    IconComponent, 
    TranslatePipe,
    ImageLoaderComponent,  
    DeleteDialogComponent, 
    GoRegisterOrLoginComponent,   
    ConfirmMessageComponent,
    LoaderComponent,   
  ],
  imports: [
    CommonModule,   
    [...shared] 
  ],
  exports: [ 
    [...shared],
    IconComponent, 
    TranslatePipe,
    ImageLoaderComponent,  
    DeleteDialogComponent, 
    GoRegisterOrLoginComponent, 
    NgxSpinnerModule,
    MatProgressSpinnerModule,
    LoaderComponent, 
    ConfirmMessageComponent,  
  ],
  providers: [DatePipe],

})
export class SharedModule { } 
