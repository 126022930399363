import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../interceptors/auth.interceptor';
import { ErrorInterceptor } from '../interceptors/error.interceptor';
import { LoadingInterceptor } from '../interceptors/loading.interceptor'; 

export const interseptors = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true 
    },
    {
        provide: HTTP_INTERCEPTORS, 
        useClass: ErrorInterceptor,
        multi: true
    }, 
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingInterceptor,
        multi: true
    }, 
]